module.exports = [{
      plugin: require('/Users/mtuchi/Workspace/heartheblindspot.org/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145578182-1"},
    },{
      plugin: require('/Users/mtuchi/Workspace/heartheblindspot.org/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/mtuchi/Workspace/heartheblindspot.org/src/intl","languages":["en","am"],"defaultLanguage":"en","redirect":true,"redirectComponent":"/Users/mtuchi/Workspace/heartheblindspot.org/src/components/redirect.js"},
    },{
      plugin: require('/Users/mtuchi/Workspace/heartheblindspot.org/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/mtuchi/Workspace/heartheblindspot.org/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
