/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import './src/css/layout.css'
import './src/css/custom.css'

export function onInitialClientRender() {
  window.isFirstRender = true;

  setTimeout(function() {
    window.isFirstRender = false;
  }, 200)
}
