/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

function SEO({ lang, meta, title, keywords, intl, pagePath }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )
  const url = pagePath
    ? `${site.siteMetadata.siteUrl}${pagePath}`
    : site.siteMetadata.siteUrl
  const metaDescription = intl.formatMessage({ id: "social.description" })
  const socialEn = `https://user-images.githubusercontent.com/6592749/64610967-09f62100-d3d9-11e9-9e1d-d00a2aa4185d.png`
  const socialAm = `https://user-images.githubusercontent.com/6592749/64683874-c0ffa480-d48c-11e9-9739-d2c59fe2cf62.png`

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${intl.formatMessage({ id: "title" })}`}
      meta={[
        {
          name: `title`,
          content: site.siteMetadata.title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${intl.formatMessage({ id: "social.title" })}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `${url}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${intl.locale === "en" ? socialEn : socialAm}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:url`,
          content: `${url}`,
        },
        {
          property: `twitter:title`,
          content: `${intl.formatMessage({ id: "social.title" })}`,
        },
        {
          property: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: `${intl.locale === "en" ? socialEn : socialAm}`,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``,
  pagePath: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  pagePath: PropTypes.string,
}

export default injectIntl(SEO)
